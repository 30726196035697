._ {
  margin: 100px auto;
  display: flex;
  align-items: center;

  max-width: 1200px;
}

.image {
  width: 55%;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

.content {
  // padding: 0 100px;
  width: 45%;
}

.heading {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin: 0;
}

.text {
  font-size: 18px;
  line-height: 24px;
  opacity: 0.5;
  margin: 10px 0 30px 0;
  font-weight: 400;
}

.list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &::before {
      content: url(/images/check.svg);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #e3e5e3;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  ._ {
    padding: 40px;
  }
}
@media screen and (max-width: 960px) {
  ._ {
    margin: 100px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .heading {
    font-size: 32px;
    line-height: 38px;
  }

  .text {
    font-size: 16px;
    line-height: 21px;
    opacity: 0.5;
    margin: 10px 0 30px 0;
    font-weight: 400;
  }

  .list {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    li {
      font-size: 14px;
    }
  }

  .image {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    img {
      width: 100%;
    }
  }

  .content {
    padding: 0 20px;
    width: 100%;
  }
}
