.terms {
  max-width: 1240px;
  padding: 120px 100px 0px 100px;

  h2 {
    font-weight: 600;
    text-align: left;
    font-size: 32px;
    padding-left: 26px;
  }

  ol {
    color: #000;
    line-height: 24px;

    li {
      margin: 10px 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .terms {
    width: 100%;
    padding: 2%;

    padding-top: 120px;
  }

  ol {
    font-size: 14px;
    color: #000;
    line-height: 18px;
  }
}
