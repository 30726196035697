._ {
  // margin: 8px 0 20px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  // padding-bottom: 10px;
}

.phone-code {
  font-family: 'Roboto';
  font-weight: 400;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;

  img {
    margin-right: 8px;
    // box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    .phone-change {
      opacity: 1;
    }
  }
}

.phone-change {
  position: absolute;
  width: 20px;
  height: 100%;
  display: flex;
  transition: 100ms ease-in-out all;
  opacity: 0;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.7);
}

.no-change {
  cursor: default;
  pointer-events: none;
}

@keyframes in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.code-list {
  background: white;
  position: absolute;
  left: -20px;
  z-index: 2147483647;
  padding: 2px 2px;
  border-radius: 4px;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.3);
  animation: in linear 100ms forwards;
}

.code-item {
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  color: #666;
  transition: 100ms ease-in-out all;

  &:hover {
    background: #e3e3e5;
    color: black;
  }
}

.input {
  width: 100%;
  height: 44px !important;
  border-radius: 4px !important;
  padding-left: 20px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  outline: none !important;
  display: flex;
  align-items: center;
  color: black;
  background: #fff !important;
  transition: 150ms ease-in-out all;
  font-family: 'Roboto' !important;
  // box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #e3e3e5 !important;
  -webkit-appearance: none;
  box-sizing: border-box;

  &::placeholder {
    color: #999 !important;
  }

  &:focus {
    // box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.3) !important;
    background: #fff !important;
    border: 1px solid #000 !important;
  }
  &:hover {
    border: 1px solid #000 !important;
    background: #fff !important;
  }
}

.input-error {
  border-bottom: 2px solid rgb(255, 0, 111);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: -2px 2px 12px rgba(255, 0, 0, 0.15);
  box-sizing: border-box;
}

.phone {
  padding-left: 85px !important;
  box-sizing: border-box;
}

.message {
  font-family: 'Montserrat' !important;
  padding: 15px 20px;
  min-height: 120px;
  max-height: 120px;
  height: 120px;
  min-width: 315px;
  resize: none;
  &:focus {
    box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.2);
  }
  box-sizing: border-box;
}

.error {
  font-family: 'Montserrat' !important;
  position: absolute;
  bottom: -10px;
  font-size: 11px;
  padding-left: 3px;
  font-weight: 500;
  color: rgb(255, 0, 111);
  box-sizing: border-box;
}

@media only screen and (max-width: 400px) {
  .input {
    font-size: 14px;
  }
}
