._ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.header_desktop {
  .head {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 30px;
    // background: white;
    z-index: 200;
    position: relative;
  }

  .logo {
    height: 30px;
  }

  .links {
    display: flex;
    list-style: none;
    margin-left: 30px;
    li {
      height: 80px;
      margin: 0 20px;
      a {
        position: relative;
        height: 80px;
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        transition: 180ms linear;
        cursor: pointer;

        svg {
          margin-left: 10px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0%;
          background: black;
          transition: 180ms linear;
        }

        &:hover {
          opacity: 0.8;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  .action {
    margin-left: auto;
    display: flex;
  }

  .product {
    border-top: 1px solid #e3e3e5;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.09);
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0;
    background: white;
    transform: translateY(-180%);
    transition: 320ms ease-in-out all;
  }

  .product_open {
    transform: translateY(0px);
  }

  .product {
    ul {
      width: 1000px;
      margin: 50px auto;
      display: flex;
      flex-flow: row wrap;
      list-style: none;

      li {
        width: 33.333%;
        margin-bottom: 30px;
        padding: 0 20px;

        a {
          text-decoration: none;

          &:hover {
            h4 {
              color: rgb(50, 218, 106);

              &::before {
                background: rgb(50, 218, 106);
              }
            }
          }
        }

        h4 {
          display: flex;
          align-items: center;
          font-weight: 600;
          color: #000;

          &::before {
            content: ' ';
            display: block;
            width: 8px;
            height: 8px;
            background: black;
            margin-right: 7px;
            border-radius: 1px;
          }
        }

        p {
          opacity: 0.7;
          color: #333;
          line-height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .header_desktop {
    display: none;
  }

  .header_mobile {
    display: block;
  }
}
@media screen and (min-width: 960px) {
  .header_desktop {
    display: block;
  }

  .header_mobile {
    display: none;
  }
}

.header_mobile {
  .head {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 15px;
    z-index: 200;
    position: relative;
  }

  .logo {
    height: 36px;
  }

  .menu {
    margin-left: auto;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .mobile {
    border-top: 1px solid #e3e3e5;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.09);
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0;
    background: white;
    transform: translateY(-130%);
    transition: 320ms ease-in-out all;
    padding-bottom: 20px;
  }

  .mobile_opened {
    transform: translateY(0px);
  }

  .links {
    list-style: none;
    margin-left: 24px;

    li {
      margin: 30px 0px;
      a {
        position: relative;
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        font-size: 24px;
        font-weight: 500;
        transition: 180ms linear;
        cursor: pointer;

        svg {
          margin-left: 8px;
          stroke-width: 3px;
        }
      }
    }
  }

  .action {
    display: flex;
    margin: 40px 44px;
  }

  .product {
    display: none;
  }

  .product_open {
    display: block;
  }

  .product {
    ul {
      list-style: none;
      padding: 0;
      li {
        width: 100%;
        margin: 15px 0;
        padding: 0 0px;

        a {
          text-decoration: none;

          &:hover {
            h4 {
              color: rgb(50, 218, 106);

              &::before {
                background: rgb(50, 218, 106);
              }
            }
          }
        }

        h4 {
          font-weight: 500;
          color: #999;
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }
}

.scrolled {
  // .head {
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.12);

  // }
  .head {
    background: white;
  }
}
