._ {
  display: inline-block;
  position: relative;
}

.dropdown {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;

  width: 500px;

  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.22);
  padding: 20px 40px;
  z-index: 9999999;
}

.header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;

  img {
    width: 50px;
    border-radius: 100%;
  }

  span {
    // margin-left: 20px;
    margin-top: 10px;
    font-size: 28px;
    // line-height: 42px;
    font-weight: 600;
    color: black;
  }
}

.box {
  background: red;
  width: 80%;
  height: 80px;
  position: absolute;
  top: 70px;
  right: 0;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
