.hero {
  margin-top: 150px;

  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.heading {
  .line1 {
    display: block;
    font-size: 56px;
  }
  .line2 {
    display: block;
    font-size: 48px;
    color: #27cc69;
  }
}

.text {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 600;
  line-height: 32px;
  max-width: 540px;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .heading {
    .line1 {
      font-size: 36px;
    }
    .line2 {
      font-size: 30px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .hero {
    max-width: 1000px;
    margin: 120px auto 00px auto;
    // padding-left: 280px;
    display: block;
    padding: 10px 20px;
    width: 100%;
  }
  .calls_table {
    margin: 30px 0 0 0;
    width: 100%;
  }
}
