.hero {
  margin-top: 200px;
  padding-left: 100px;
  max-width: 50%;
}

.heading {
  .line1 {
    display: block;
    font-size: 56px;
  }
  .line2 {
    display: block;
    font-size: 48px;
    color: #27cc69;
  }
}

.text {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 600;
  line-height: 36px;
  max-width: 540px;
  margin-top: 0;
}

.action {
  margin-top: 40px;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 506px;
  background: rgba(220, 251, 218, 0.8);
  z-index: 2;
}

.hero_image {
  width: 300px;
  position: absolute;
  bottom: -30%;
  left: -15%;
}

.hero_iphone {
  width: 800px;
  position: absolute;
  bottom: -60%;
  left: -25%;
}

@media screen and (max-width: 1200px) {
  .hero_image {
    width: 300px;
    position: absolute;
    bottom: -30%;
    left: -5%;
  }

  .hero_iphone {
    width: 800px;
    position: absolute;
    bottom: -60%;
    left: -20%;
  }
}

.proof {
  margin: 240px auto 80px auto;
  max-width: 1000px;
  text-align: center;
}

.proof_heading {
  font-weight: 500;
  opacity: 0.3;
  font-size: 18px;
}

.proof_logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin: 10px;
  }
}

.testimonials {
  margin: 20px 100px;
  background: rgb(11, 181, 82);
  padding: 30px 60px;
}

.testimonials_name {
  font-size: 42px;
  font-weight: 600;
  color: white;
}
.testimonials_job {
  margin: 3px 0;
  color: white;
  opacity: 0.7;
  font-size: 18px;
}
.quote {
  margin: 40px 100px 20px 100px;
  display: block;
  width: 500px;
  font-weight: 500;
  color: white;
  font-size: 24px;
  line-height: 32px;
}

.integrations {
  max-width: 1000px;
  margin: 100px auto;
}

.integrations_heading {
  text-align: center;
  font-weight: 32px;
  line-height: 36px;
  font-weight: 600;
}

.integrations_images {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin: 10px;
  }
}

.cta {
  text-align: center;
  margin-top: 150px;
}

.cta_heading {
  font-size: 36px;
}

.cta_action {
  margin-top: 30px;
}

@media screen and (max-width: 960px) {
  .hero {
    margin-top: 140px;
    padding: 0 20px;
    max-width: 100%;
  }

  .heading {
    .line1 {
      font-size: 36px;
    }
    .line2 {
      font-size: 30px;
    }
  }

  .text {
    font-size: 21px;
    opacity: 0.9;
    font-weight: 600;
    line-height: 28px;
    max-width: 540px;
    margin-top: 0;
  }

  .action {
    margin-top: 40px;
  }

  .cover {
    position: relative;
    background: none;
    z-index: 2;
  }
  .hero_image {
    width: 200px;
    position: relative;
    top: 10%;
    left: 10%;
  }

  .hero_iphone {
    width: 450px;
    position: absolute;
    top: 30%;
    left: 5%;
  }

  .proof {
    margin: 0px auto 80px auto;
    padding: 10px;
  }

  .integrations {
    max-width: 1000px;
    margin: 20px auto;
    padding: 10px;
  }
}
