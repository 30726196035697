@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600&display=swap&subset=latin-ext);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

* {
  box-sizing: border-box !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.button____33bom {
  display: inline-flex;
  align-items: center;
  height: 44px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.button_default__2xYmv {
  background: #000;
  color: white;
}

.button_primary__3C1qu {
  background: rgb(11, 181, 82);
  border: 2px solid rgb(11, 181, 82);
  color: white;
}

.button_empty__1Qydg {
  border: 1px solid #000;
  color: #000;
  font-weight: 400;
}

.button_link__2ExQ7 {
  color: #000;
}

.button_margin-right__BeTPm {
  margin-right: 15px;
}

.button_margin-left__3IThi {
  margin-left: 15px;
}
.header____poaFN {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.header_header_desktop__8ncpt .header_head__ytskw {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 10px 30px;
  z-index: 200;
  position: relative;
}
.header_header_desktop__8ncpt .header_logo__13VgX {
  height: 30px;
}
.header_header_desktop__8ncpt .header_links__1Mip0 {
  display: flex;
  list-style: none;
  margin-left: 30px;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li {
  height: 80px;
  margin: 0 20px;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li a {
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: 180ms linear;
  cursor: pointer;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li a svg {
  margin-left: 10px;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0%;
  background: black;
  transition: 180ms linear;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li a:hover {
  opacity: 0.8;
}
.header_header_desktop__8ncpt .header_links__1Mip0 li a:hover::after {
  width: 100%;
}
.header_header_desktop__8ncpt .header_action__2xCD3 {
  margin-left: auto;
  display: flex;
}
.header_header_desktop__8ncpt .header_product__fPLMg {
  border-top: 1px solid #e3e3e5;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.09);
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  background: white;
  transform: translateY(-180%);
  transition: 320ms ease-in-out all;
}
.header_header_desktop__8ncpt .header_product_open__276hp {
  transform: translateY(0px);
}
.header_header_desktop__8ncpt .header_product__fPLMg ul {
  width: 1000px;
  margin: 50px auto;
  display: flex;
  flex-flow: row wrap;
  list-style: none;
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li {
  width: 33.333%;
  margin-bottom: 30px;
  padding: 0 20px;
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li a {
  text-decoration: none;
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li a:hover h4 {
  color: rgb(50, 218, 106);
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li a:hover h4::before {
  background: rgb(50, 218, 106);
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li h4 {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #000;
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li h4::before {
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  background: black;
  margin-right: 7px;
  border-radius: 1px;
}
.header_header_desktop__8ncpt .header_product__fPLMg ul li p {
  opacity: 0.7;
  color: #333;
  line-height: 24px;
}

@media screen and (max-width: 960px) {
  .header_header_desktop__8ncpt {
    display: none;
  }

  .header_header_mobile__1fnti {
    display: block;
  }
}
@media screen and (min-width: 960px) {
  .header_header_desktop__8ncpt {
    display: block;
  }

  .header_header_mobile__1fnti {
    display: none;
  }
}
.header_header_mobile__1fnti .header_head__ytskw {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 10px 15px;
  z-index: 200;
  position: relative;
}
.header_header_mobile__1fnti .header_logo__13VgX {
  height: 36px;
}
.header_header_mobile__1fnti .header_menu__2sB3U {
  margin-left: auto;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.header_header_mobile__1fnti .header_menu__2sB3U:hover {
  opacity: 0.5;
}
.header_header_mobile__1fnti .header_mobile__3pZKn {
  border-top: 1px solid #e3e3e5;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.09);
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  background: white;
  transform: translateY(-130%);
  transition: 320ms ease-in-out all;
  padding-bottom: 20px;
}
.header_header_mobile__1fnti .header_mobile_opened__h-o_v {
  transform: translateY(0px);
}
.header_header_mobile__1fnti .header_links__1Mip0 {
  list-style: none;
  margin-left: 24px;
}
.header_header_mobile__1fnti .header_links__1Mip0 li {
  margin: 30px 0px;
}
.header_header_mobile__1fnti .header_links__1Mip0 li a {
  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  transition: 180ms linear;
  cursor: pointer;
}
.header_header_mobile__1fnti .header_links__1Mip0 li a svg {
  margin-left: 8px;
  stroke-width: 3px;
}
.header_header_mobile__1fnti .header_action__2xCD3 {
  display: flex;
  margin: 40px 44px;
}
.header_header_mobile__1fnti .header_product__fPLMg {
  display: none;
}
.header_header_mobile__1fnti .header_product_open__276hp {
  display: block;
}
.header_header_mobile__1fnti .header_product__fPLMg ul {
  list-style: none;
  padding: 0;
}
.header_header_mobile__1fnti .header_product__fPLMg ul li {
  width: 100%;
  margin: 15px 0;
  padding: 0 0px;
}
.header_header_mobile__1fnti .header_product__fPLMg ul li a {
  text-decoration: none;
}
.header_header_mobile__1fnti .header_product__fPLMg ul li a:hover h4 {
  color: rgb(50, 218, 106);
}
.header_header_mobile__1fnti .header_product__fPLMg ul li a:hover h4::before {
  background: rgb(50, 218, 106);
}
.header_header_mobile__1fnti .header_product__fPLMg ul li h4 {
  font-weight: 500;
  color: #999;
  margin: 0;
  font-size: 16px;
}

.header_scrolled__1XdK- {
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.12);
}
.header_scrolled__1XdK- .header_head__ytskw {
  background: white;
}
.footer____3mgZL {
  background: rgb(9, 28, 9);
  margin-top: 100px;
  color: white;
}

.footer_cols__24tTe {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
}

.footer_col__Sip35 {
  margin: 10px;
  max-width: 300px;
}
.footer_col__Sip35 ul {
  list-style: none;
  padding: 0;
}
.footer_col__Sip35 ul li:first-of-type {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
.footer_col__Sip35 ul li {
  margin: 20px 0;
}
.footer_col__Sip35 ul li a {
  color: #fff;
  opacity: 0.7;
  text-decoration: none;
  font-size: 16px;
}

.footer_cta__3EBmu {
  padding: 50px 30px;
  border-bottom: 1px solid rgb(39, 50, 39);
  text-align: center;
}
.footer_cta__3EBmu img {
  margin-bottom: 20px;
}
.footer_cta__3EBmu h3 {
  font-size: 28px;
  font-weight: 400;
  margin-top: 10px;
}

@media screen and (max-width: 960px) {
  .footer_col__Sip35 {
    width: 100%;
  }
}
.main_hero__12MTl {
  margin-top: 200px;
  padding-left: 100px;
  max-width: 50%;
}

.main_heading__1AROe .main_line1__2DfBJ {
  display: block;
  font-size: 56px;
}
.main_heading__1AROe .main_line2__13Jw3 {
  display: block;
  font-size: 48px;
  color: #27cc69;
}

.main_text__3hPkl {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 600;
  line-height: 36px;
  max-width: 540px;
  margin-top: 0;
}

.main_action__2bFIH {
  margin-top: 40px;
}

.main_cover__3RvF0 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 506px;
  background: rgba(220, 251, 218, 0.8);
  z-index: 2;
}

.main_hero_image__3zwiG {
  width: 300px;
  position: absolute;
  bottom: -30%;
  left: -15%;
}

.main_hero_iphone__n1wHG {
  width: 800px;
  position: absolute;
  bottom: -60%;
  left: -25%;
}

@media screen and (max-width: 1200px) {
  .main_hero_image__3zwiG {
    width: 300px;
    position: absolute;
    bottom: -30%;
    left: -5%;
  }

  .main_hero_iphone__n1wHG {
    width: 800px;
    position: absolute;
    bottom: -60%;
    left: -20%;
  }
}
.main_proof__2AJNF {
  margin: 240px auto 80px auto;
  max-width: 1000px;
  text-align: center;
}

.main_proof_heading__1Y-32 {
  font-weight: 500;
  opacity: 0.3;
  font-size: 18px;
}

.main_proof_logos__3Rs8_ {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.main_proof_logos__3Rs8_ img {
  margin: 10px;
}

.main_testimonials__A-Uqh {
  margin: 20px 100px;
  background: rgb(11, 181, 82);
  padding: 30px 60px;
}

.main_testimonials_name__2UTQr {
  font-size: 42px;
  font-weight: 600;
  color: white;
}

.main_testimonials_job__rR00E {
  margin: 3px 0;
  color: white;
  opacity: 0.7;
  font-size: 18px;
}

.main_quote__Bj9Js {
  margin: 40px 100px 20px 100px;
  display: block;
  width: 500px;
  font-weight: 500;
  color: white;
  font-size: 24px;
  line-height: 32px;
}

.main_integrations__1ddQ9 {
  max-width: 1000px;
  margin: 100px auto;
}

.main_integrations_heading___wm7l {
  text-align: center;
  font-weight: 32px;
  line-height: 36px;
  font-weight: 600;
}

.main_integrations_images__2I_IN {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.main_integrations_images__2I_IN img {
  margin: 10px;
}

.main_cta__1HlX3 {
  text-align: center;
  margin-top: 150px;
}

.main_cta_heading__18Yr- {
  font-size: 36px;
}

.main_cta_action__3iDgX {
  margin-top: 30px;
}

@media screen and (max-width: 960px) {
  .main_hero__12MTl {
    margin-top: 140px;
    padding: 0 20px;
    max-width: 100%;
  }

  .main_heading__1AROe .main_line1__2DfBJ {
    font-size: 36px;
  }
  .main_heading__1AROe .main_line2__13Jw3 {
    font-size: 30px;
  }

  .main_text__3hPkl {
    font-size: 21px;
    opacity: 0.9;
    font-weight: 600;
    line-height: 28px;
    max-width: 540px;
    margin-top: 0;
  }

  .main_action__2bFIH {
    margin-top: 40px;
  }

  .main_cover__3RvF0 {
    position: relative;
    background: none;
    z-index: 2;
  }

  .main_hero_image__3zwiG {
    width: 200px;
    position: relative;
    top: 10%;
    left: 10%;
  }

  .main_hero_iphone__n1wHG {
    width: 450px;
    position: absolute;
    top: 30%;
    left: 5%;
  }

  .main_proof__2AJNF {
    margin: 0px auto 80px auto;
    padding: 10px;
  }

  .main_integrations__1ddQ9 {
    max-width: 1000px;
    margin: 20px auto;
    padding: 10px;
  }
}
.section____2xHBN {
  margin: 100px auto;
  display: flex;
  align-items: center;
  max-width: 1200px;
}

.section_image__3wtU4 {
  width: 55%;
  align-items: center;
  justify-content: center;
}
.section_image__3wtU4 img {
  width: 100%;
}

.section_content__3R6Fj {
  width: 45%;
}

.section_heading__1msJK {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin: 0;
}

.section_text__1ioIR {
  font-size: 18px;
  line-height: 24px;
  opacity: 0.5;
  margin: 10px 0 30px 0;
  font-weight: 400;
}

.section_list__jMdLA {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
.section_list__jMdLA li {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.section_list__jMdLA li::before {
  content: url(/images/check.svg);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #e3e5e3;
  margin-right: 12px;
  flex-shrink: 0;
}

@media screen and (max-width: 1200px) {
  .section____2xHBN {
    padding: 40px;
  }
}
@media screen and (max-width: 960px) {
  .section____2xHBN {
    margin: 100px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .section_heading__1msJK {
    font-size: 32px;
    line-height: 38px;
  }

  .section_text__1ioIR {
    font-size: 16px;
    line-height: 21px;
    opacity: 0.5;
    margin: 10px 0 30px 0;
    font-weight: 400;
  }

  .section_list__jMdLA {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
  }
  .section_list__jMdLA li {
    font-size: 14px;
  }

  .section_image__3wtU4 {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
  .section_image__3wtU4 img {
    width: 100%;
  }

  .section_content__3R6Fj {
    padding: 0 20px;
    width: 100%;
  }
}
.inline-callback____22EUv {
  display: inline-block;
  position: relative;
}

.inline-callback_dropdown__iqRvU {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  width: 500px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.22);
  padding: 20px 40px;
  z-index: 9999999;
}

.inline-callback_header__3ffGv {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.inline-callback_header__3ffGv img {
  width: 50px;
  border-radius: 100%;
}
.inline-callback_header__3ffGv span {
  margin-top: 10px;
  font-size: 28px;
  font-weight: 600;
  color: black;
}

.inline-callback_box__3Q6Mp {
  background: red;
  width: 80%;
  height: 80px;
  position: absolute;
  top: 70px;
  right: 0;
}

.inline-callback_action__36r_v {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.input____3hRa5 {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.input_phone-code__DmVgB {
  font-family: "Roboto";
  font-weight: 400;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;
}
.input_phone-code__DmVgB img {
  margin-right: 8px;
}
.input_phone-code__DmVgB:hover .input_phone-change__1ttgT {
  opacity: 1;
}

.input_phone-change__1ttgT {
  position: absolute;
  width: 20px;
  height: 100%;
  display: flex;
  transition: 100ms ease-in-out all;
  opacity: 0;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.7);
}

.input_no-change__13pLS {
  cursor: default;
  pointer-events: none;
}

@-webkit-keyframes input_in__bFFXL {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes input_in__bFFXL {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.input_code-list__26yk4 {
  background: white;
  position: absolute;
  left: -20px;
  z-index: 2147483647;
  padding: 2px 2px;
  border-radius: 4px;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.3);
  -webkit-animation: input_in__bFFXL linear 100ms forwards;
          animation: input_in__bFFXL linear 100ms forwards;
}

.input_code-item__1oDFK {
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  color: #666;
  transition: 100ms ease-in-out all;
}
.input_code-item__1oDFK:hover {
  background: #e3e3e5;
  color: black;
}

.input_input__38Ycy {
  width: 100%;
  height: 44px !important;
  border-radius: 4px !important;
  padding-left: 20px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  outline: none !important;
  display: flex;
  align-items: center;
  color: black;
  background: #fff !important;
  transition: 150ms ease-in-out all;
  font-family: "Roboto" !important;
  border: 1px solid #e3e3e5 !important;
  -webkit-appearance: none;
  box-sizing: border-box;
}
.input_input__38Ycy::-webkit-input-placeholder {
  color: #999 !important;
}
.input_input__38Ycy:-ms-input-placeholder {
  color: #999 !important;
}
.input_input__38Ycy::-ms-input-placeholder {
  color: #999 !important;
}
.input_input__38Ycy::placeholder {
  color: #999 !important;
}
.input_input__38Ycy:focus {
  background: #fff !important;
  border: 1px solid #000 !important;
}
.input_input__38Ycy:hover {
  border: 1px solid #000 !important;
  background: #fff !important;
}

.input_input-error__2qWa- {
  border-bottom: 2px solid rgb(255, 0, 111);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: -2px 2px 12px rgba(255, 0, 0, 0.15);
  box-sizing: border-box;
}

.input_phone__1gT2U {
  padding-left: 85px !important;
  box-sizing: border-box;
}

.input_message__3QOZU {
  font-family: "Montserrat" !important;
  padding: 15px 20px;
  min-height: 120px;
  max-height: 120px;
  height: 120px;
  min-width: 315px;
  resize: none;
  box-sizing: border-box;
}
.input_message__3QOZU:focus {
  box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.2);
}

.input_error__3g4iW {
  font-family: "Montserrat" !important;
  position: absolute;
  bottom: -10px;
  font-size: 11px;
  padding-left: 3px;
  font-weight: 500;
  color: rgb(255, 0, 111);
  box-sizing: border-box;
}

@media only screen and (max-width: 400px) {
  .input_input__38Ycy {
    font-size: 14px;
  }
}
.terms_terms__2RpuC {
  max-width: 1240px;
  padding: 120px 100px 0px 100px;
}
.terms_terms__2RpuC h2 {
  font-weight: 600;
  text-align: left;
  font-size: 32px;
  padding-left: 26px;
}
.terms_terms__2RpuC ol {
  color: #000;
  line-height: 24px;
}
.terms_terms__2RpuC ol li {
  margin: 10px 0;
}

@media screen and (max-width: 600px) {
  .terms_terms__2RpuC {
    width: 100%;
    padding: 2%;
    padding-top: 120px;
  }

  ol {
    font-size: 14px;
    color: #000;
    line-height: 18px;
  }
}
.switch_wrapper__2XQD8 {
  display: block;
  align-items: center;
  flex-shrink: 0;
}

.switch_switch__3W7z7 {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}
.switch_switch__3W7z7 input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch_switch__3W7z7 input:checked + .switch_slider__2ld7o {
  background-color: rgb(0, 111, 255);
}
.switch_switch__3W7z7 input:checked + .switch_slider__2ld7o:before {
  transform: translateX(18px);
}

.switch_slider__2ld7o {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 14px;
}
.switch_slider__2ld7o:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 100%;
  z-index: 4;
}

.switch_check__vpBHE {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 2;
}

.switch_x__-SFQI {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
}

.switch_label1__3v1ro {
  font-size: 12px;
  margin-right: 10px;
  font-weight: 600;
  color: #aaa;
}

.switch_label2__16m-o {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
  color: #aaa;
}
.pricing____1k5Qq {
  max-width: 1000px;
  margin: 120px auto 50px auto;
}

.pricing_hero__9as-D {
  max-width: 1000px;
  margin: 180px auto 50px auto;
  display: flex;
  align-items: center;
}

.pricing_heading__1DCHZ .pricing_line1__2Ar4A {
  display: block;
  font-size: 56px;
}
.pricing_heading__1DCHZ .pricing_line2__Qygmx {
  display: block;
  font-size: 48px;
  color: #27cc69;
}

.pricing_text__1LemV {
  font-size: 20px;
  opacity: 0.6;
  font-weight: 400;
  line-height: 36px;
  max-width: 540px;
  margin-top: 0;
}

.pricing_name__1_T2R {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
}

.pricing_popular__8jHa_ .pricing_name__1_T2R {
  color: #27cc69;
}

.pricing_price__2Td4K {
  margin: 20px 0;
  font-weight: 600;
}
.pricing_price__2Td4K .pricing_value__1cSLA {
  font-size: 46px;
  font-weight: 700;
  line-height: 34px;
}
.pricing_price__2Td4K .pricing_currency__XXuOT {
  font-size: 18px;
  color: #333;
  padding-left: 6px;
}
.pricing_price__2Td4K .pricing_per__2B2hf {
  color: #7a7e7b;
  display: block;
  font-weight: 400;
  margin-top: 8px;
  font-size: 14px;
}
.pricing_price__2Td4K small {
  display: block;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  margin-top: 5px;
}

.pricing_switcher__1jXYB {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 15px;
}

.pricing_switcher2__1sIYF {
  display: flex;
  justify-content: flex-start;
}

.pricing_discount__kEck1 {
  position: absolute;
  top: -50px;
  left: 0;
  text-align: left;
  color: rgb(255, 174, 0);
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: 600;
}

.pricing_plans__1GlB3 {
  overflow-x: scroll;
  width: 1000px;
  margin: 80px auto 0 auto;
  border-collapse: collapse;
}
.pricing_plans__1GlB3 td {
  width: 25%;
  padding: 0px 20px;
  position: relative;
}
.pricing_plans__1GlB3 td ul {
  list-style: none;
  padding: 0;
}
.pricing_plans__1GlB3 .pricing_popular__8jHa_ {
  background: rgba(213, 254, 210, 0.742);
}

.pricing_row_description__3f2a0 {
  display: flex;
  align-items: center;
}
.pricing_row_description__3f2a0 h3 {
  font-size: 18px;
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
}
.pricing_row_description__3f2a0 p {
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  opacity: 0.5;
}

.pricing_table_feature__3aPiw {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.pricing_good__xjrK8 {
  color: #000;
}
.pricing_good__xjrK8 svg {
  margin-right: 10px;
  color: #000;
}

.pricing_bad__2gNFd {
  color: rgb(128, 128, 128);
}
.pricing_bad__2gNFd svg {
  margin-right: 14px;
}

.pricing_calls_table__pxVxy {
  box-shadow: 0 4px 30px rgba(43, 86, 40, 0.142);
  border-radius: 12px;
  padding: 20px 20px 20px 20px;
  margin-left: 100px;
}

.pricing_calls__1h8f_ {
  font-size: 17px;
  padding: 10px 20px;
}

.pricing_calls_price__nVg04 {
  font-size: 26px;
  padding: 10px 20px;
  font-weight: 700;
}
.pricing_calls_price__nVg04 small {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}

@media screen and (max-width: 960px) {
  .pricing_heading__1DCHZ .pricing_line1__2Ar4A {
    font-size: 36px;
  }
  .pricing_heading__1DCHZ .pricing_line2__Qygmx {
    font-size: 30px;
  }

  .pricing_text__1LemV {
    font-size: 16px;
    line-height: 24px;
  }

  .pricing_hero__9as-D {
    max-width: 1000px;
    margin: 120px auto 0px auto;
    display: block;
    padding: 10px 20px;
    width: 100%;
  }

  .pricing_calls_table__pxVxy {
    margin: 30px 0 0 0;
    width: 100%;
  }
}
.proof_hero__rYz6T {
  margin-top: 150px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.proof_heading__2eZZc .proof_line1__3p8fl {
  display: block;
  font-size: 56px;
}
.proof_heading__2eZZc .proof_line2__1GzfM {
  display: block;
  font-size: 48px;
  color: #27cc69;
}

.proof_text__1qJUg {
  font-size: 24px;
  opacity: 0.9;
  font-weight: 600;
  line-height: 32px;
  max-width: 540px;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .proof_heading__2eZZc .proof_line1__3p8fl {
    font-size: 36px;
  }
  .proof_heading__2eZZc .proof_line2__1GzfM {
    font-size: 30px;
  }

  .proof_text__1qJUg {
    font-size: 16px;
    line-height: 24px;
  }

  .proof_hero__rYz6T {
    max-width: 1000px;
    margin: 120px auto 0px auto;
    display: block;
    padding: 10px 20px;
    width: 100%;
  }

  .proof_calls_table__2Boar {
    margin: 30px 0 0 0;
    width: 100%;
  }
}
.a_heading__2_9GP {
  margin: 200px 0;
  text-align: center;
}
.a_heading__2_9GP .a_line1__3TCtj {
  display: block;
  font-size: 56px;
}
.a_heading__2_9GP .a_line2__5KUuz {
  display: block;
  font-size: 48px;
  color: #27cc69;
}
