._ {
  max-width: 1000px;
  margin: 120px auto 50px auto;
}

.hero {
  max-width: 1000px;
  margin: 180px auto 50px auto;
  // padding-left: 280px;
  display: flex;
  align-items: center;
}

.heading {
  .line1 {
    display: block;
    font-size: 56px;
  }
  .line2 {
    display: block;
    font-size: 48px;
    color: #27cc69;
  }
}

.text {
  font-size: 20px;
  opacity: 0.6;
  font-weight: 400;
  line-height: 36px;
  max-width: 540px;
  margin-top: 0;
}

.name {
  font-size: 21px;
  text-transform: uppercase;
  // margin: 0;
  font-weight: 700;
  // margin-bottom: 5px;
  padding-top: 10px;
}

.popular {
  .name {
    color: #27cc69;
  }
}

.price {
  margin: 20px 0;
  font-weight: 600;

  .value {
    font-size: 46px;
    font-weight: 700;
    line-height: 34px;
  }

  .currency {
    font-size: 18px;
    color: #333;
    padding-left: 6px;
  }

  .per {
    color: #7a7e7b;
    display: block;
    font-weight: 400;
    margin-top: 8px;
    font-size: 14px;
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 5px;
  }
}

.switcher {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 15px;
}
.switcher2 {
  display: flex;
  justify-content: flex-start;
}

.discount {
  position: absolute;
  top: -50px;
  left: 0;
  text-align: left;
  color: rgb(255, 174, 0);
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: 600;
}

.plans {
  // width: 100%;
  overflow-x: scroll;
  width: 1000px;
  margin: 80px auto 0 auto;
  border-collapse: collapse;

  td {
    width: 25%;
    padding: 0px 20px;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
    }
  }

  .popular {
    background: rgba(213, 254, 210, 0.742);
  }
}

.row_description {
  display: flex;
  align-items: center;

  h3 {
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 20px;
    opacity: 0.5;
  }
}

.table_feature {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.good {
  color: #000;
  svg {
    margin-right: 10px;
    color: #000;
  }
}
.bad {
  color: rgb(128, 128, 128);
  svg {
    margin-right: 14px;
  }
}

.calls_table {
  box-shadow: 0 4px 30px rgba(43, 86, 40, 0.142);
  border-radius: 12px;
  padding: 20px 20px 20px 20px;
  margin-left: 100px;
}

.calls {
  font-size: 17px;
  padding: 10px 20px;
}

.calls_price {
  font-size: 26px;
  padding: 10px 20px;
  font-weight: 700;

  small {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.8;
  }
}

@media screen and (max-width: 960px) {
  .heading {
    .line1 {
      font-size: 36px;
    }
    .line2 {
      font-size: 30px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .hero {
    max-width: 1000px;
    margin: 120px auto 00px auto;
    // padding-left: 280px;
    display: block;
    padding: 10px 20px;
    width: 100%;
  }
  .calls_table {
    margin: 30px 0 0 0;
    width: 100%;
  }
}
