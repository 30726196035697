._ {
  background: rgb(9, 28, 9);
  margin-top: 100px;

  color: white;
}

.cols {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
}

.col {
  margin: 10px;
  max-width: 300px;
  ul {
    list-style: none;
    padding: 0;

    li:first-of-type {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
    }

    li {
      margin: 20px 0;

      a {
        color: #fff;
        opacity: 0.7;
        text-decoration: none;

        font-size: 16px;
      }
    }
  }
}

.cta {
  padding: 50px 30px;
  border-bottom: 1px solid rgb(39, 50, 39);

  text-align: center;
  img {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px;
  }
}

@media screen and (max-width: 960px) {
  .col {
    width: 100%;
  }
}
