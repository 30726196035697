$default: #000;
$primary: rgb(11, 181, 82);

._ {
  display: inline-flex;
  align-items: center;
  height: 44px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.default {
  background: $default;
  color: white;
}

.primary {
  background: $primary;
  border: 2px solid $primary;
  color: white;
}

.empty {
  border: 1px solid $default;
  color: $default;
  font-weight: 400;
}
.link {
  color: $default;
}

.margin-right {
  margin-right: 15px;
}
.margin-left {
  margin-left: 15px;
}
