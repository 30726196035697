.heading {
  margin: 200px 0;
  text-align: center;
  .line1 {
    display: block;
    font-size: 56px;
  }
  .line2 {
    display: block;
    font-size: 48px;
    color: #27cc69;
  }
}
